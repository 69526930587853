import {Grid, Text} from "@hudoro/neron";
import Cookies from "js-cookie";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import HeaderText from "../../components/atoms/HeaderText";
import Loading from "../../components/atoms/Loading";
import StyledButton from "../../components/atoms/StyledButton";
import MainTemplate from "../../components/templates/MainTemplate";
import {
  getQuestions,
  sendNotifEmail,
  submitAnswers,
} from "../../services/survey";
import {notify} from "../../utils/functions";
import {UserContainer} from "./styles";
import InformasiKeteranganPenilaian from "./InformasiKeteranganPenilaian";
import QuestionWrapper from "../../components/atoms/QuestionWrapper";
import QuestionItem from "../../components/atoms/QuestionItem";
import Ratarata from "./Ratarata";

export default function SurveyForm() {
  const [sections, setSections] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [errorId, setErrorId] = useState([]);


  // new state
  const [questionValue, setQuestionValue] = useState([]);
  // end new state

  const fetchQuestions = useCallback(async () => {
    try {
      const response = await getQuestions({
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        path: params.surveyId,
      });
      setSections(response.data.data.data);
      Cookies.set("surveyId", response.data.data.data.id);
    } catch (err) {
      console.log(err);
    }
  }, [params]);

  const onSubmit = async (e) => {
    let erorrId = [];
    console.log("quetsionvalue", questionValue);
    try {
      setisLoading(true);
      e.preventDefault();
      let newValue = [...questionValue];
      let error = false;
      newValue.forEach((e) => {
        if (e.questionType === "LINEAR_SCALE") {
          if (Number(e.answer.value) <= 7 && !e.feedback) {
            erorrId.push(e.questionId);

            error = true;
          }
        }
      });

      setErrorId(erorrId);
      if (error) {
        var scrollDiv =
          document.getElementById(`${erorrId[0]}`).offsetTop - 100;
        window.scrollTo({top: scrollDiv, behavior: "smooth"});

        return notify(
          "Mohon melengkapi keterangan untuk item yang mendapatkan point 7 kebawah",
          "error"
        );
      }
      const findIndexHaveFeedback = newValue.findIndex(
        (item) =>
          item.questionType === "LINEAR_SCALE" &&
          Number(item.answer.value) > 7 &&
          item.feedback
      );

      if (findIndexHaveFeedback > -1) {
        delete newValue[findIndexHaveFeedback].feedback;
      }

      await submitAnswers({
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        path: `${sections.id}/submits`,
        body: {answers: newValue},
      });

      // if (type !== "manajemen-risiko") {
      await sendNotifEmail({
        path: `notification/email`,
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: {
          surveyId: sections.id,
        },
      });
      // }
      notify(
        "Terima kasih atas kesediaan Bapak / ibu untuk memberikan penilaian jujur dan objektif dalam rangka perbaikan kinerja kami",
        "success"
      );

      // localStorage.clear();
      navigate(-1);
    } catch (err) {
      notify(err.message, "error");
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  useEffect(() => {
    if (sections) {
      for (let i of sections.sections) {
        const questionsHasAnswer = i.questions
          .filter((item) => item.answer?.value)
          .map((item) => ({
            questionId: item.id,
            questionType: item.component.name,
            answer: {
              value: String(item.answer.value),
            },
            ...(item.answer.feedback ? {feedback: item.answer.feedback} : {}),
          }));
        setQuestionValue((prev) => [...prev, ...questionsHasAnswer]);
      }
    }
  }, [sections]);

  return (
    <MainTemplate
      title={sections?.title}
      description="Silahkan isi kuesioner dengan lengkap"
    >
      <HeaderText title={sections?.title} />
      {params.type !== "manajemen-risiko" && <InformasiKeteranganPenilaian />}
      <UserContainer>
        <Text variant="h4" style={{fontSize: "14px", fontWeight: 500}}>
          {Cookies.get("name") || ""}
        </Text>
        <Text variant="mute" style={{fontSize: "12px", lineHeight: "18px"}}>
          {Cookies.get("job") || ""}
        </Text>
      </UserContainer>
      <form onSubmit={onSubmit}>
        <Grid container flexDirection="column" gap={24}>
          {sections &&
            sections?.sections?.map((section, idx) => (
              <QuestionWrapper style={{padding: 0}} key={section.id}>
                <Text variant="h4" style={{fontSize: "16px", fontWeight: 500}}>
                  {idx + 1}. {section.name}
                </Text>
                <Grid
                  container
                  flexDirection="column"
                  style={{
                    marginTop: 20,
                    backgroundColor: "white",
                    borderRadius: "10px",
                    padding: "8px",
                  }}
                  gap={10}
                >
                  {section.questions.map((question) => {
                    return (
                      <QuestionItem
                        question={question}
                        key={question.id}
                        isBorder={section.questions[0].id == question.id}
                        questionValue={questionValue}
                        setQuestionValue={setQuestionValue}
                        errorId={errorId}

                      />
                    );
                  })}
                </Grid>
              </QuestionWrapper>
            ))}
          {sections?.sections ? (
            <Ratarata sections={sections} questionValue={questionValue} />
          ) : null}
          <StyledButton
            variant="primary"
            style={{fontSize: "18px", padding: "8px"}}
          >
            {isLoading ? <Loading /> : "Submit"}
          </StyledButton>
        </Grid>
      </form>
    </MainTemplate>
  );
}
