import React, {useEffect} from "react";
import {
  LabelInsideRadio,
  QuestionDesc,
  QuestionLabel,
  TabsContainer,
  Wrapper,
} from "./styles";
import {Text} from "@hudoro/neron";

const LinearScaleInput = ({data, questionValue, setQuestionValue, errorId}) => {
  const questionType = data.component.name;
  let isError = Boolean(errorId.find((item) => item == data.id));
  console.log("isError", isError);

  const handleChangeValue = (value) => {
    const newValue = [...questionValue];
    const findPosition = newValue.findIndex(
      (item) => item.questionId === data.id
    );
    if (findPosition < 0) {
      newValue.push({
        questionId: data.id,
        questionType: questionType,
        answer: {
          value: String(value),
        },
      });
      setQuestionValue(newValue);
      return;
    } else {
      newValue[findPosition].answer.value = String(value);
      setQuestionValue(newValue);
    }
  };

  const handleAddFeedBack = (value) => {
    const newValue = [...questionValue];
    const findPosition = newValue.findIndex(
      (item) => item.questionId === data.id
    );
    newValue[findPosition].feedback = String(value);
    setQuestionValue(newValue);
  };

  return (
    <>
      <QuestionLabel>{data.name}</QuestionLabel>
      <QuestionDesc>{data.description || ""}</QuestionDesc>
      <Wrapper>
        <TabsContainer style={{margin: "13px 0 6px"}}>
          <input
            type="radio"
            name={`radio/${data.id}/${questionType}`}
            required={data.isRequired}
            style={{position: "absolute", zIndex: "-999"}}
          />
          {new Array(data.component.value.end.number)
            .fill(0)
            .map((_, index) => (
              <LabelInsideRadio onClick={() => handleChangeValue(index + 1)}>
                <input
                  type="radio"
                  name={`radio/${data.id}/${questionType}`}
                  value={index + 1}
                  defaultChecked={index + 1 === (data.answer?.value || null)}
                />
                <span>{index + 1}</span>
              </LabelInsideRadio>
            ))}
        </TabsContainer>
        {questionValue.find((item) => item.questionId === data.id)?.answer
          .value <= 7 ? (
          <div
            id={`${data.id}`}
            style={{
              display: "flex",
              marginTop: "15px",
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            <textarea
              style={{
                width: "100%",
                resize: "none",
                minHeight: "50px",
                borderRadius: "5px",
                borderColor: isError ? "#E82A2D" : "#aaa",
                padding: 10,
              }}
              placeholder="Keterangan"
              autoFocus
              onChange={(e) => handleAddFeedBack(e.target.value)}
              defaultValue={data.answer?.feedback}
            />
            {isError ? (
              <p style={{fontSize: "12px", color: "#E82A2D"}}>
                Mohon melengkapi keterangan untuk item yang mendapatkan point 7
                kebawah
              </p>
            ) : null}

          </div>
        ) : null}
      </Wrapper>
    </>
  );
};

export default LinearScaleInput;
